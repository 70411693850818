export const AppConfig = {
	site_name: "Market Dynamics",
	title: "Trade smarter with Market Dynamics. No coding required",
	description: "Trade smarter with Market Dynamics. No coding required",
	locale: "en",
};

export const NEXT_URL =
	process.env.NEXT_PUBLIC_FRONTEND_URL || "http://localhost:3000";

export const API_URL = process.env.API_URL || "https://app.mktdynamics.com/web";
export const REACT_APP_API_SERVER_URL = "https://app.mktdynamics.com";
