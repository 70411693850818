import { atom } from "recoil";

export interface User {
    '2fa': string | null;
    '2fa_secret': string | null;
    email: string | null;
    first_name: string | null;
    is_email_auth_enabled: string | null;
    is_preferences_filled: '0' | '1' | 0 | 1 | null;
    last_name: string | null;
    login_status: number | null;
    success: boolean | null;
    user_id: string | null;
    location: string | null;
    language: null | 'en' | 'pt' | 'es';
}

const defaultUserState = <User>{
    '2fa': null,
    '2fa_secret': null,
    email: null,
    first_name: null,
    is_email_auth_enabled: null,
    is_preferences_filled: null,
    last_name: null,
    login_status: null,
    success: null,
    user_id: null,
    location: null,
    language: null
}

export const userState = atom({
    key: 'userState',
    default: defaultUserState
})